import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import './Home.css';

function Home() {
    const [data, setData] = useState({
        celcius: 10,
        name: 'Lagos',
        humidity: 10,
        speed: 2,
        image: '/images/cloudy1.png'
    })
    const [name, setName] = useState('');
    const [error, setError] = useState('');

        const handleClick = () =>  {
            if (name !== "") {
                const apiUrl = `https://api.openweathermap.org/data/2.5/weather?q=${name}&appid=2daa41813daedbc2e2c35196891140eb&units=metric`;
                axios.get(apiUrl)
                .then(res => {
                    let imagePath = '';
                    if(res.data.weather[0].main == "Clouds"){
                        imagePath = "/images/cloudy1.png"
                    }else if(res.data.weather[0].main = "Clear") {
                        imagePath = "/images/clearsky.png"
                    }else if(res.data.weather[0].main = "Rain") {
                        imagePath = "/images/rainy.png"
                    }else if(res.data.weather[0].main = "Drizzle") {
                        imagePath = "/images/drizzle1.png"
                    }else if(res.data.weather[0].main = "Mist") {
                        imagePath = "/images/misty1.png"
                    }else if(res.data.weather[0].main = "Sunny"){
                        imagePath = "/images/sunny.png"
                    }else {
                        imagePath = "/images/cloudy1.png"
                    }
                    console.log(res.data);
                    setData({...data, celcius: res.data.main.temp, name: res.data.name, 
                        humidity: res.data.main.humidity, speed: res.data.wind.speed, image: imagePath})
                        setError('');
                })
                .catch(err => {
                    if (err.response.status == 404) {
                        setError("Invalid City Name")
                    } else{
                        setError('');
                    }
                    console.log(err)
                });
            }
        }

    return (
        <div className="container">
            <div className="weather">
                <div className="search">
                <input type='text' placeholder='Enter City Name'  onChange={e => setName(e.target.value)}/>
                <button><img src="/images/search.png" width={25} onClick={handleClick} alt=''/></button>
                </div>

                <div className="error">
                    <p>{error}</p>
                </div>
                <div className="winfo">
                    <img src={data.image} alt="" className="icon"/>
                    <h2>{data.name}</h2>
                    <h1>{Math.round(data.celcius)}°C</h1>
                    <div className="details">
                        <div className="col">
                            <img src="/images/humidity1.png" />
                            <div className="humidity">
                                <p>{Math.round(data.humidity)}%</p>
                                <p>Humidity</p>
                            </div>
                         </div>
                        <div className="col">
                        <img src="/images/wind1.png" />
                            <div className="wind">
                                <p>{Math.round(data.speed)}km/h</p>
                                <p>Wind</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;